import React, { useState } from 'react';
import type { CookieObj } from '@noths/polaris-client-gdpr-compliance';
import { acceptsFunctionalCookies } from '@noths/polaris-client-gdpr-compliance';
import type { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';

import { RecommendationsContextProvider } from 'src/components/contexts/RecommendationsContext';
import { MembershipActivationModal } from 'src/components/organisms/MembershipActivationModal/MembershipActivationModal';
import { RibbonsPage } from 'src/components/templates/RibbonsPage/RibbonsPage';
import { SKIP_CONTENT_TARGET_ID } from 'src/constants/elementIds';
import { getPageContent } from 'src/content/getPageContent';
import type { ContentPageProps, PageConfiguration, PageMetadata } from 'src/types/PageProps';
import { getPageMetadata } from 'src/utils/getPageMetadata';
import { getRecommendationsSessionId } from 'src/utils/getRecommendationsSessionId';

const pageConfig: PageConfiguration = {
  env: process.env.NODE_ENV,
  contentType: 'homepage',
  pageType: 'homepage',
  trackingCategory: 'Home',
  hasSeoLinkedData: true,
};

interface getContentTypeProps {
  alreadyAMember: boolean;
  alreadyActivated: boolean;
}

export const getServerSideProps: GetServerSideProps<ContentPageProps> = async (context) => {
  const pageMetadata: PageMetadata = {
    ...getPageMetadata(context),
    functionalCookiesConsented: acceptsFunctionalCookies(context.req.cookies as CookieObj),
    recommendationsSessionId: getRecommendationsSessionId(context),
  };

  try {
    const pageContent = await getPageContent(pageConfig, pageMetadata);

    return {
      props: {
        pageConfig,
        pageMetadata,
        pageContent: pageContent.pageContent,
        pageEventCategory: pageConfig.trackingCategory,
        navData: pageContent.navData,
        topBannerData: pageContent.topBannerData,
      },
    };
  } catch {
    return {
      notFound: true,
    };
  }
};

const membershipActivationSources = ['email', 'order-confirmation'];

const hasMembershipActivated = (query: ParsedUrlQuery) => {
  return membershipActivationSources.includes(query.membershipActivationSource as string);
};

const isDuplicateMembershipPurchase = (query: ParsedUrlQuery) => {
  return query.duplicateMemberships === 'true';
};

const isMembershipActivatedAlready = (query: ParsedUrlQuery) => {
  return query.isMembershipActivatedAlready === 'true';
};

const getContentType = ({ alreadyAMember, alreadyActivated }: getContentTypeProps) => {
  if (alreadyAMember) {
    return 'alreadyAMember';
  }

  if (alreadyActivated) {
    return 'alreadyActivated';
  }

  return 'welcome';
};

const Homepage = (props: ContentPageProps) => {
  const { query } = useRouter();

  const [showMembershipActivationModal, setShowMembershipActivationModal] = useState(
    hasMembershipActivated(query),
  );

  const alreadyAMember = isDuplicateMembershipPurchase(query);
  const alreadyActivated = isMembershipActivatedAlready(query);

  const content = getContentType({ alreadyAMember, alreadyActivated });

  return (
    <div id={SKIP_CONTENT_TARGET_ID}>
      {showMembershipActivationModal && (
        <MembershipActivationModal
          content={content}
          onModalClose={() => setShowMembershipActivationModal(false)}
        />
      )}
      <RecommendationsContextProvider {...props}>
        <RibbonsPage {...props} />
      </RecommendationsContextProvider>
    </div>
  );
};

export default Homepage;
