import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import type { ContentType } from './types';

const { spacing, text } = theme;

export const overlay = css`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0.75;
  background-color: var(--neutral-black-base);
  pointer-events: none;
`;

export const activationModal = (content: ContentType) => css`
  font-size: ${pxToRem(text.BODY.MD.fontSize)};
  color: var(--neutral-black-base);
  text-align: center;

  img {
    margin-top: ${pxToRem(spacing.xxxxl)};
  }

  h2 {
    margin: 0;
    padding-top: ${pxToRem(spacing.sm)};
    color: var(--brand-bluple-base);
  }

  p {
    padding: 0 96px;
    margin-bottom: ${pxToRem(spacing.xxl)};
    text-indent: 10px;
  }

  button {
    margin-bottom: ${pxToRem(spacing.xxl)};
  }

  ${mediaQuery(
    { max: minBreakpoints.M },
    css`
      img {
        width: 298px;
        height: 78px;
        margin-top: ${pxToRem(spacing.xxl)};
      }

      h2 {
        padding-top: ${pxToRem(spacing.lg)};
      }

      p {
        padding: 0 ${pxToRem(spacing.xl)};
        text-align: center;
      }

      button {
        margin-bottom: 0;
      }
    `,
  )}

  ${content === 'alreadyAMember' &&
  css`
    h2 {
      padding: ${pxToRem(spacing.sm)} 200px;
    }

    ${mediaQuery(
      { max: minBreakpoints.M },
      css`
        h2 {
          padding: ${pxToRem(spacing.lg)} 10px 0;
        }
      `,
    )}
  `}

  ${content === 'alreadyActivated' &&
  css`
    p {
      padding: 0 100px;
    }

    ${mediaQuery(
      { max: minBreakpoints.M },
      css`
        p {
          padding: 0 ${pxToRem(spacing.xl)};
        }
      `,
    )}
  `}
`;

export const highlightedText = css`
  color: var(--brand-purple-base);
`;

export const linkText = css`
  color: var(--brand-purple-base);
  text-decoration: underline;
  cursor: pointer;
`;
