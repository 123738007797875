import { sendGAEvent } from '@noths/polaris-client-google-analytics';

const eventLabel = {
  alreadyAMember: 'You are already a member',
  welcome: 'Welcome to the club',
  alreadyActivated: 'Membership already activated',
};

export const trackMembershipActivationModalImpression = (content: string) => {
  sendGAEvent({
    event: 'custom_event',
    event_action: 'Impression',
    event_category: 'Membership',
    event_label: `${eventLabel[content]} - success message`,
  });
};

export const trackMembershipActivationModalCTAClick = (content: string) => {
  sendGAEvent({
    event: 'custom_event',
    event_action: 'Click',
    event_category: 'Membership',
    event_label: `${eventLabel[content]} - start shopping`,
  });
};
